<template>
  <div class="alert rounded-md p-3" :class="classes">
    <div v-if="$slots.start">
      <slot name="start"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'FrAlert',
  props: {
    color: {
      type: String,
      default() {
        return 'gray-light'
      }
    },
    danger: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const classes = computed(() => {
      let arr = [];
      if (props.color === 'destructive' || props.color === 'danger' || props.color === 'error' || props.danger) {
        return ['bg-red-light/20']
      } else if (props.color === 'primary' || props.primary) {
        return ['bg-lightblue/20']
      } else if (props.color === 'warning' || props.warning) {
        return ['bg-yellow/20']
      } else if (props.color === 'success' || props.success) {
        return ['bg-green/20']
      } else if (props.color === 'info' || props.info) {
        return ['bg-gray-lightest']
      }

      return [];
    });

    return {
      classes
    }
  }
})
</script>
